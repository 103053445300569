import React, { useState, useEffect, useCallback } from "react"
import api from "../utils/api"
import LoadingPlaceholder from "./LoadingPlaceholder"
import UpdatePlaceholder from "./UpdatePlaceholder"
import config from "../config.json"
import { createDetailsWidget } from "@livechat/agent-app-sdk"
import TurndownService from "turndown"
import { createLink, createTitle } from "../utils/links"
import { marked } from "marked"
import ReactionComponent from "./ReactionComponent"
import AlertDismissible from "./ErrorComponent"
import { getChats, updateSelectedChat, retrieveChatHistory, sendChatsToAskguru } from "../utils/misc"

let hasExecuted = false
let chatsChecked = false

const MainForm = ({ vendorAccessToken, askguruAccessToken, tokenLoadingState }) => {
    const [loaderUIVisible, setLoaderUIVisible] = useState(true)
    const [mainUIVisible, setMainUIVisible] = useState(false)
    const [updateUIVisible, setUpdateUIVisible] = useState(false)
    const [updateProgressValue, setUpdateProgressValue] = useState(0)
    const [loadingState, setLoadingState] = useState("loading")
    const [query, setQuery] = useState("")
    const [isCtrlPressed, setIsCtrlPressed] = useState(false)
    const [isWaitingForAnswer, setIsWaitingForAnswer] = useState(false)
    const [reactionUIVisible, setReactionUIVisible] = useState(false)
    const [showAnswerFields, setShowAnswerFields] = useState(false)
    const [answerLinks, setAnswerLinks] = useState([])
    const [selectedChat, setSelectedChat] = useState(null)
    const [chats, setChats] = useState(null)
    const [requestId, setRequestId] = useState("")
    const [errorState, setErrorState] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const turndownService = new TurndownService()
    const tokenizer = new marked.Tokenizer()
    const renderer = new marked.Renderer()
    tokenizer.lheading = function () {
        return false
    }
    renderer.link = function (href, title, text) {
        return `<a target="_blank" href="${href}">${text}</a>`
    }
    marked.setOptions({
        tokenizer: tokenizer,
        renderer: renderer,
        headerIds: false,
        mangle: false,
    })

    const panic = useCallback((message) => {
        setErrorState(true)
        setErrorMessage(message)
        setIsWaitingForAnswer(false)
    }, [])

    const syncronizeChatsChunks = useCallback(
        (chatsToUpdate, offset, step) => {
            if (chatsToUpdate.length <= offset) {
                setUpdateUIVisible(false)
            } else {
                let chatsUpdateChunk = chatsToUpdate.slice(offset, offset + step)
                new Promise((resolve, reject) => {
                    let chatHistories = []
                    let chatMetadatas = []
                    let coroutineCounter = 0
                    chatsUpdateChunk.forEach((chat) => {
                        retrieveChatHistory(vendorAccessToken, askguruAccessToken, chat.id, chat.user.id)
                            .then((chatHistory) => {
                                chatHistories.push({
                                    history: chatHistory,
                                    user: chat.user,
                                })
                                chatMetadatas.push({
                                    id: chat.id,
                                    title: chat.user + "::" + chat.id,
                                    timestamp: chat.timestamp,
                                    security_groups: chat.security_groups,
                                })
                                coroutineCounter += 1
                                if (coroutineCounter === chatsUpdateChunk.length) {
                                    resolve([chatHistories, chatMetadatas])
                                }
                            })
                            .catch((error) => {
                                api.askguru
                                    .logEvent({
                                        accessToken: askguruAccessToken,
                                        type: "LIVECHAT_DATA_ERROR",
                                        context: {
                                            error: "Error retrieving chat history from LiveChat",
                                            error_object: error,
                                        },
                                    })
                                    .catch((err) => {
                                        console.log("Unable to send an event")
                                    })
                                coroutineCounter += 1
                                if (coroutineCounter === chatsUpdateChunk.length) {
                                    resolve([chatHistories, chatMetadatas])
                                }
                            })
                    })
                }).then(([chatHistories, chatMetadatas]) => {
                    if (chatHistories.length > 0) {
                        sendChatsToAskguru(chatHistories, chatMetadatas, askguruAccessToken)
                            .then(() => {
                                // todo: update progress here
                                let newProgressValue =
                                    Math.min(chatsToUpdate.length, offset + step) / chatsToUpdate.length
                                setUpdateProgressValue(newProgressValue)
                                syncronizeChatsChunks(chatsToUpdate, offset + step, step)
                            })
                            .catch((error) => {
                                panic("Couldn't synchronize chats with AskGuru servers")
                                api.askguru
                                    .logEvent({
                                        accessToken: askguruAccessToken,
                                        type: "LIVECHAT_DATA_ERROR",
                                        context: {
                                            error: "Error sending chats to askguru via sendChatsToAskguru",
                                            error_object: error,
                                        },
                                    })
                                    .catch((err) => {
                                        console.log("Unable to send an event")
                                    })
                            })
                    } else {
                        // probably should go to next batch here, but idk if last was empty we either done or sth seriously wrong
                        console.log("empty chat histories to send")
                    }
                })
            }
        },
        [askguruAccessToken, panic, vendorAccessToken]
    )

    const syncAskguruChats = useCallback(
        (chats) => {
            let chatsToUpdate = []
            api.askguru
                .getCollection({
                    accessToken: askguruAccessToken,
                    collection: "chats",
                })
                .then((collection) => {
                    const existingChats = {}
                    collection.data.documents.forEach((doc) => {
                        existingChats[doc.id] = doc.timestamp
                    })
                    console.log("Existing chats: ", existingChats)
                    chats.forEach((chat) => {
                        if (!existingChats[chat.id] || existingChats[chat.id] < chat.timestamp) {
                            chatsToUpdate.push(chat)
                        }
                    })
                    syncronizeChatsChunks(chatsToUpdate, 0, 20)
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        // collection does not exist yet, pushing all
                        chatsToUpdate = chats
                        api.askguru
                            .logEvent({
                                accessToken: askguruAccessToken,
                                type: "LIVECHAT_DATA_ERROR",
                                context: {
                                    error: "Not an error: just received 404 on collection request. About to send chats",
                                    chatsToUpdate: chatsToUpdate.length,
                                },
                            })
                            .catch((err) => {
                                console.log("Unable to send an event")
                            })
                        syncronizeChatsChunks(chatsToUpdate, 0, 20)
                    } else {
                        // TODO: notify user that something went wrong

                        api.askguru
                            .logEvent({
                                accessToken: askguruAccessToken,
                                type: "LIVECHAT_DATA_ERROR",
                                context: {
                                    error: "Get collection request returned " + error.response.status,
                                    data: error.response,
                                },
                            })
                            .catch((err) => {
                                console.log("Unable to send an event")
                            })
                        panic("Could")
                    }
                })
        },
        [askguruAccessToken, panic, syncronizeChatsChunks]
    )

    const getLivechatChats = useCallback(() => {
        setLoadingState("fetching")
        getChats(vendorAccessToken, askguruAccessToken)
            .then(([chats, activeChats]) => {
                if (chats.length === 0) {
                    api.askguru
                        .logEvent({
                            accessToken: askguruAccessToken,
                            type: "LIVECHAT_DATA_ERROR",
                            context: {
                                error: "LiveChat API returned 0 chats",
                            },
                        })
                        .catch((err) => {
                            console.log("Unable to send an event")
                        })
                    setLoadingState("failed")
                } else {
                    console.log("TRIGGERING syncAskguruChats")
                    setChats(chats)
                    setLoaderUIVisible(false)
                    setUpdateUIVisible(true)
                    setMainUIVisible(true)
                    syncAskguruChats(chats)
                }
                api.askguru.logEvent({
                    accessToken: askguruAccessToken,
                    type: "LIVECHAT_DATA_ERROR",
                    context: {
                        error: "Not an error: just logging # of chats we got through getChats in getLivechatChats",
                        error_object: chats.length,
                    },
                })
            })
            .catch((error) => {
                setLoadingState("failed")
                api.askguru
                    .logEvent({
                        accessToken: askguruAccessToken,
                        type: "LIVECHAT_DATA_ERROR",
                        context: {
                            error: "getChats ended with error in getLivechatChats",
                            error_object: error,
                        },
                    })
                    .catch((err) => {
                        console.log("Unable to send an event")
                    })
            })
    }, [askguruAccessToken, syncAskguruChats, vendorAccessToken])

    useEffect(() => {
        if (!hasExecuted){
            hasExecuted = true
            createDetailsWidget().then((widget) => {
                widget.on("customer_profile", (profile) => {
                    if (profile.source === "chats" || profile.source === "archives") {
                        setChats((prevChats) => {
                            let selectedChat = undefined
                            if (prevChats) {
                                selectedChat = prevChats.find((chat) => chat.id === profile.chat.chat_id)
                            }
                            if (selectedChat === undefined) {
                                getChats(vendorAccessToken, askguruAccessToken)
                                    .then(([chats_, activeChats_]) => {
                                        selectedChat = chats_.find((chat) => chat.id === profile.chat.chat_id)
                                        setSelectedChat(selectedChat)
                                        updateSelectedChat(selectedChat, askguruAccessToken, vendorAccessToken)
                                            .then((query) => {
                                                if (query) {
                                                    setQuery(query)
                                                }
                                            })
                                            .catch(() => {
                                                console.log("Selected chat was not set properly")
                                            })
                                        return chats_
                                    })
                                    .catch((error) => {
                                        api.askguru
                                            .logEvent({
                                                accessToken: askguruAccessToken,
                                                type: "LIVECHAT_DATA_ERROR",
                                                context: {
                                                    error: "getChats in widget callback ended with error",
                                                    error_object: error,
                                                },
                                            })
                                            .catch((err) => {
                                                console.log("Unable to send an event")
                                            })
                                    })
                            } else {
                                setSelectedChat(selectedChat)
                                console.log("Selected chat was found")
                                updateSelectedChat(selectedChat, askguruAccessToken, vendorAccessToken)
                                    .then((query) => {
                                        if (query) {
                                            setQuery(query)
                                        }
                                    })
                                    .catch(() => {
                                        console.log("Selected chat was not set properly")
                                    })
                            }
                            return prevChats
                        })
                    }
                })
            }).catch(error => {
                console.log("Livechat widget error: " +  error)
            })
        }
    }, [])

    useEffect(() => {
        // entry point to component
        if (tokenLoadingState === "failed") {
            setLoadingState("failed")
        } else if (vendorAccessToken && askguruAccessToken) {
            // received tokens, we can try requesting chats from livecaht
            if (!chatsChecked){
                chatsChecked = true
                getLivechatChats()
            }
        }
    }, [askguruAccessToken, getLivechatChats, tokenLoadingState, vendorAccessToken])

    const getRanking = useCallback(() => {
        setReactionUIVisible(false)
        setShowAnswerFields(true)
        setIsWaitingForAnswer(true)
        setAnswerLinks([])
        api.askguru
            .getRanking({
                accessToken: askguruAccessToken,
                collections: [],
                query: query,
                document: selectedChat ? selectedChat.id : undefined,
                documentCollection: selectedChat ? "chats" : undefined,
                topK: 5,
            })
            .then((response) => {
                const processedAnswerLinks = response.data.sources.map((source) => {
                    if (source.collection === "chats") {
                        var chat = chats.find((chat) => chat.id === source.id)
                        source.id = chat.last_thread_id
                    }
                    return source
                })
                setAnswerLinks(processedAnswerLinks)
                console.log("Processed resources", processedAnswerLinks)
            })
            .catch((error) => {
                api.askguru
                    .logEvent({
                        accessToken: askguruAccessToken,
                        type: "LIVECHAT_DATA_ERROR",
                        context: {
                            error: "getRanking response failed",
                            error_object: error,
                        },
                    })
                    .catch((err) => {
                        console.log("Unable to send an event")
                    })
                panic("Error retrieving relevant sources")
                console.log(error)
            })
    }, [askguruAccessToken, chats, panic, query, selectedChat])

    const getAnswer = useCallback(() => {
        const answerElement = document.getElementById("answer-text")
        answerElement.textContent = "Thinking..."
        api.askguru
            .getAnswer({
                accessToken: askguruAccessToken,
                // TODO retrieve all available collections from BE
                // and put them into state
                collections: [],
                query: query,
                // TODO
                document: selectedChat ? selectedChat.id : undefined,
                documentCollection: selectedChat ? "chats" : undefined,
                stream: config.streamGetAnswer,
            })
            .then((eventSource) => {
                var askguruSourcePattern = new RegExp(config.askguruSourcePattern)
                console.log("Event source: ", eventSource)
                if (config.streamGetAnswer) {
                    var request_id = ""
                    var sources = []
                    var generated_sources = []

                    var text = ""
                    eventSource.onmessage = (event) => {
                        if (answerElement.textContent === "Thinking...") {
                            answerElement.textContent = ""
                        }
                        const jsonObj = JSON.parse(event.data)
                        if (jsonObj.answer) {
                            request_id = jsonObj.request_id
                            sources = jsonObj.sources

                            text += jsonObj.answer

                            const match = text.match(askguruSourcePattern)
                            if (match) {
                                const docIdx = match[1]
                                const source = sources[docIdx]
                                source.title = createTitle({ title: source.title, collection: source.collection })
                                if (source.collection === "chats") {
                                    var chat = chats.find((chat) => chat.id === source.id)
                                    source.id = chat.last_thread_id
                                }
                                const link = createLink({ id: source.id, collection: source.collection })
                                source.link = link

                                var idx =
                                    generated_sources.findIndex(
                                        (existingSource) =>
                                            existingSource.id === source.id &&
                                            existingSource.collection === source.collection
                                    ) + 1
                                if (idx === 0) {
                                    generated_sources.push(source)
                                    idx = generated_sources.length
                                }

                                text = text.replace(askguruSourcePattern, ``)
                            }
                            answerElement.innerHTML = marked(text)
                        }
                    }

                    eventSource.onerror = (error) => {
                        if (eventSource.readyState === 0) {
                            console.log("The stream has been closed by the server.")
                            console.log("Sources:", sources)
                            console.log("Generated sources:", generated_sources)
                            // turning that off for agents
                            // if (generated_sources.length > 0) {
                            //     text += "\n\n**Sources:**\n"
                            //     generated_sources.forEach((source, idx) => {
                            //         text += `${idx + 1}. [${source.title}](${source.link})\n`
                            //         answerElement.innerHTML = marked(text)
                            //     })
                            // }
                            setRequestId(request_id)
                            setReactionUIVisible(true)
                        } else {
                            console.log("An error occurred:", error)
                            answerElement.textContent = "Sorry! Was not able to find an answer."
                            api.askguru.logEvent({
                                accessToken: askguruAccessToken,
                                type: "LIVECHAT_DATA_ERROR",
                                context: {
                                    error: "getAnswer response failed, stream branch",
                                    error_object: error,
                                },
                            })
                            panic("Error retrieving answer")
                        }
                        setIsWaitingForAnswer(false)
                        eventSource.close()
                    }
                } else {
                    console.log("Response: ", eventSource)
                    if (eventSource) {
                        setRequestId(eventSource.data.request_id)
                        setReactionUIVisible(true)
                        text = eventSource.data.answer.replace(askguruSourcePattern, ``)
                        answerElement.textContent = text
                    } else {
                        answerElement.textContent = "No answer found"
                    }
                    setIsWaitingForAnswer(false)
                }
            })
            .catch((error) => {
                panic("Error retrieving answer")
                api.askguru
                    .logEvent({
                        accessToken: askguruAccessToken,
                        type: "LIVECHAT_DATA_ERROR",
                        context: {
                            error: "getAnswer response failed",
                            error_object: error,
                        },
                    })
                    .catch((err) => {
                        console.log("Unable to send an event")
                    })
            })
    }, [askguruAccessToken, chats, panic, query, selectedChat])

    const onSubmit = useCallback(() => {
        setErrorState(false)
        var click_hint = document.getElementById("click-hint")
        click_hint.innerText = "(click on it to copy!)"
        getRanking()
        getAnswer()
    }, [getAnswer, getRanking])

    const handleKeyDown = (event) => {
        if (event.key === "Control") {
            setIsCtrlPressed(true)
        } else if (event.key === "Enter" && isCtrlPressed) {
            // Trigger the button's onClick function
            event.preventDefault()
            document.getElementById("button-submit").click()
        }
    }

    const handleKeyUp = (event) => {
        if (event.key === "Control") {
            setIsCtrlPressed(false)
        }
    }

    return (
        <>
            {errorState && <AlertDismissible message={errorMessage} />}
            {loaderUIVisible && (
                <div className="container-fluid">
                    <LoadingPlaceholder state={loadingState} />
                </div>
            )}
            {updateUIVisible && (
                <div className="container-fluid" id="update-placeholder">
                    <UpdatePlaceholder progress={updateProgressValue} />
                </div>
            )}
            {mainUIVisible && (
                <div className="container-fluid">
                    <div className="container-fluid">
                        <p className="fw-light my-2 text-center">
                            {selectedChat ? (
                                // write in bold
                                <>
                                    Current customer: <strong>{selectedChat.user.name}</strong>
                                </>
                            ) : (
                                "Select LiveChat chat or paste any query below!"
                            )}
                        </p>
                    </div>
                    <div className="container-fluid" id="question">
                        <p className="fw-light my-2 mt-4">Paste customer’s question here:</p>

                        <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                            <textarea
                                className="form-control fw-light my-2"
                                rows="3"
                                name="query"
                                id="query"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder={config.queryPlaceholder}
                            />

                            <button
                                className="btn btn-success my-2"
                                id="button-submit"
                                disabled={query.length === 0 || isWaitingForAnswer}
                                style={{
                                    cursor: query.length === 0 || isWaitingForAnswer ? "not-allowed" : "pointer",
                                }}
                                onClick={onSubmit}
                            >
                                Get answer &nbsp;
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-arrow-right"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div
                            className="mt-3"
                            style={{
                                display: showAnswerFields ? "block" : "none",
                            }}
                        >
                            <span className="badge bg-success rounded-1 p-2 fw-light">1.</span>
                            <span className="text-start fw-light my-2">&nbsp; Suggested answer &nbsp; </span>
                            <span className="text-start fw-light my-2 text-grey" id="click-hint">
                                (click on it to copy!)
                            </span>
                        </div>
                        <div id="answer-text-wrapper">
                            <p
                                id="answer-text"
                                className="bg-light fw-light rounded mb-2 p-3 mt-2"
                                onClick={() => {
                                    var this_elem = document.getElementById("answer-text")
                                    //html to md via turndown
                                    navigator.clipboard
                                        .writeText(turndownService.turndown(this_elem.innerHTML))
                                        .catch((err) => {
                                            panic(
                                                "Could not access your clipboard to copy text. Please check permissions"
                                            )
                                            console.log("Unable to copy to clipboard", err)
                                        })
                                    var click_hint = document.getElementById("click-hint")
                                    click_hint.innerText = "(copied!)"
                                    api.askguru.setReaction({
                                        accessToken: askguruAccessToken,
                                        requestId: requestId,
                                        answerCopied: true,
                                    })
                                }}
                                style={{
                                    cursor: "pointer",
                                    // whiteSpace: "pre-line",
                                    display: showAnswerFields ? "block" : "none",
                                }}
                            ></p>
                        </div>
                        {reactionUIVisible && (
                            <div
                                style={{
                                    display: "flex",
                                }}
                            >
                                <ReactionComponent askGuruToken={askguruAccessToken} requestId={requestId} />
                            </div>
                        )}

                        <div
                            id="links-wrapper"
                            style={{
                                display: answerLinks.length > 0 ? "block" : "none",
                            }}
                            className="mt-3"
                        >
                            <span className="badge bg-success rounded-1 p-2 fw-light">2.</span>
                            <span className="text-start fw-light my-2">&nbsp; Most relevant docs &nbsp; </span>

                            <ol className="list-group list-group-numbered fw-light my-2">
                                {answerLinks.map(({ id, collection, title, summary }) => (
                                    <li className="list-group-item" key={id}>
                                        <a
                                            className="text-decoration-none"
                                            href={createLink({ id, collection })}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {createTitle({ title, collection })}
                                        </a>
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default MainForm
