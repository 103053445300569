import React from "react"
import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"

function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}

const UpdatePlaceholder = ({ progress }) => {
    let adjusted_value = 10 + progress * 90
    return (
        <div className="container-fluid">
            <p className="fw-light my-2 text-center">Chat history is being updated...</p>
            <p className="fw-light my-2 text-center">
                <i>You can use the app, but keep in mind we need to index all the chats for the best experience</i>
            </p>
            <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={adjusted_value} />
            </Box>
        </div>
    )
}

export default UpdatePlaceholder
