import React, { useState } from "react"

const DislikeOptions = ({ onSubmitDislikeClick }) => {
    const [dislikeOptions, setDislikeOptions] = useState("")
    const [dislikeComment, setDislikeComment] = useState("")
    const [dislikeSubmitVisible, setDislikeSubmitVisible] = useState()

    const submitOption = () => {
        onSubmitDislikeClick([dislikeOptions, dislikeComment])
        var text = document.getElementById("dislikeComment")
        text.value = ""
    }

    return (
        <div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="dislikeOption"
                    id="radioWrongAnswer"
                    value="wrong_answer"
                    onChange={() => {
                        setDislikeOptions("wrong_answer")
                        setDislikeSubmitVisible(true)
                    }}
                ></input>
                <label className="form-check-label" htmlFor="radioWrongAnswer">
                    Wrong answer
                </label>
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="dislikeOption"
                    id="radioIncompleteAnswer"
                    value="incomplete_answer"
                    onChange={() => {
                        setDislikeOptions("incomplete_answer")
                        setDislikeSubmitVisible(true)
                    }}
                ></input>
                <label className="form-check-label" htmlFor="radioIncompleteAnswer">
                    Incomplete answer
                </label>
            </div>
            <div className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name="dislikeOption"
                    id="radioOffensiveAnswer"
                    value="offensive_answer"
                    onChange={(event) => {
                        setDislikeOptions("offensive_answer")
                        setDislikeSubmitVisible(true)
                    }}
                ></input>
                <label className="form-check-label" htmlFor="radioOffensiveAnswer">
                    Offensive answer
                </label>
            </div>
            <textarea
                className="form-control fw-light my-2"
                name="dislikeComment"
                id="dislikeComment"
                placeholder="Please, provide the answer you consider correct"
                onChange={(event) => {
                    setDislikeComment(event.target.value)
                }}
            />
            <button
                className="btn btn-danger my-2"
                style={{
                    display: dislikeSubmitVisible ? "block" : "none",
                }}
                onClick={submitOption}
            >
                Submit dislike &nbsp;
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check2"
                    viewBox="0 0 16 16"
                >
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                </svg>
            </button>
        </div>
    )
}

export default DislikeOptions
