import logo from "../img/logo.svg"
import vendor_logo from "../img/vendor_logo.png"

const Header = () => {
    return (
        <header className="container-fluid">
            <div className="navbar d-flex justify-content-between">
                <a href="/" className="navbar-brand">
                    <img src={vendor_logo} width="100" alt="" />
                </a>
                <a href="/" className="navbar-brand">
                    <img src={logo} width="100" alt="" />
                </a>
            </div>
        </header>
    )
}

export default Header
