import api from "./api"

function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
}

export const retrieveChatHistory = (vendorAccessToken, askguruAccessToken, chatId, userId, pageId = undefined) => {
    return new Promise((resolve, reject) => {
        var chat_history = []
        api.livechat
            .listThreads({ accessToken: vendorAccessToken, chatId: chatId, pageId: pageId })
            .then((threads) => {
                var nextPageId = threads.data.next_page_id
                threads.data.threads.forEach((thread) => {
                    thread.events.forEach((message) => {
                        if (message.text && message.type === "message") {
                            var role = userId === message.author_id ? "user" : "assistant"
                            chat_history.push({
                                role: role,
                                content: message.text,
                                timestamp: new Date(message.created_at).getTime(),
                            })
                        }
                    })
                })
                if (nextPageId) {
                    retrieveChatHistory(vendorAccessToken, askguruAccessToken, chatId, userId, nextPageId)
                        .then((chat_history_next) => {
                            resolve(chat_history.concat(chat_history_next))
                        })
                        .catch((err) => {
                            api.askguru
                                .logEvent({
                                    accessToken: askguruAccessToken,
                                    type: "LIVECHAT_DATA_ERROR",
                                    context: {
                                        error: "Error in retrieveChatHistory",
                                        error_object: err,
                                    },
                                })
                                .catch((err) => {
                                    console.log("Unable to send an event")
                                })
                        })
                } else {
                    resolve(chat_history)
                }
            })
            .catch((err) => {
                if (err.response.status === 429 || err.response.code === 408) {
                    delay(500).then(() => {
                        retrieveChatHistory(vendorAccessToken, askguruAccessToken, chatId, userId, pageId)
                            .then((chat_history_next) => {
                                resolve(chat_history.concat(chat_history_next))
                            })
                            .catch((err) => {
                                api.askguru
                                    .logEvent({
                                        accessToken: askguruAccessToken,
                                        type: "LIVECHAT_DATA_ERROR",
                                        context: {
                                            error:
                                                "Error after retry in retrieveChatHistory, code " + err.response.status,
                                            error_object: err,
                                        },
                                    })
                                    .catch((err) => {
                                        console.log("Unable to send an event")
                                    })
                            })
                    })
                } else {
                    reject(err)
                }
            })
    })
}

export const sendChatsToAskguru = (chats, chatMetadatas, askguruAccessToken, retry = true) => {
    return new Promise((resolve, reject) => {
        api.askguru
            .updateCollection({
                accessToken: askguruAccessToken,
                collection: "chats",
                contentType: "chats",
                chats: chats,
                metadata: chatMetadatas,
            })
            .then((response) => {
                console.log("Uploaded new chunks: ", response.data.n_chunks)
                resolve()
            })
            .catch((error) => {
                if (retry) {
                    api.askguru.logEvent({
                        accessToken: askguruAccessToken,
                        type: "LIVECHAT_DATA_ERROR",
                        context: {
                            error: "updateCollection request to askguru ended with error, but we'll retry",
                            error_object: error,
                        },
                    })
                    api.askguru
                        .updateCollection({
                            accessToken: askguruAccessToken,
                            collection: "chats",
                            contentType: "chats",
                            chats: chats,
                            metadata: chatMetadatas,
                        })
                        .then((response) => {
                            console.log("Uploaded new chunks: ", response.data.n_chunks)
                            resolve()
                        })
                        .catch((error) => {
                            api.askguru.logEvent({
                                accessToken: askguruAccessToken,
                                type: "LIVECHAT_DATA_ERROR",
                                context: {
                                    error: "updateCollection failed and no retry will happen",
                                    error_object: error,
                                },
                            })
                            reject(error)
                        })
                } else {
                    reject(error)
                }
            })
    })
}

export const getChats = (vendorAccessToken, askguruAccessToken, page_id = undefined) => {
    return new Promise((resolve, reject) => {
        var chats = []
        var activeChats = []
        api.livechat
            .listChats({ accessToken: vendorAccessToken, page_id: page_id })
            .then((allChats) => {
                console.log("All chats: ", allChats)
                let next_page_id = allChats.data.next_page_id

                allChats.data.chats_summary.forEach((chat) => {
                    var customer = chat.users.find((user) => user.type === "customer")
                    var instance = {
                        id: chat.id,
                        user: {
                            name: customer.name ? customer.name : "Unnamed customer",
                            id: customer.id,
                        },
                        // chat.last_thread_summary.created_at : "2023-05-03T11:29:30.450000Z"
                        timestamp: new Date(chat.last_thread_summary.created_at).getTime(),
                        security_groups: chat.access.group_ids,
                        last_thread_id: chat.last_thread_summary.id,
                    }
                    chats.push(instance)
                    if (chat.last_thread_summary.active) {
                        activeChats.push(instance)
                    }
                })
                if (next_page_id === undefined) {
                    resolve([chats, activeChats])
                } else {
                    getChats(vendorAccessToken, askguruAccessToken, next_page_id)
                        .then(([nextChats, nextActiveChats]) => {
                            resolve([chats.concat(nextChats), activeChats.concat(nextActiveChats)])
                        })
                        .catch((error) => {
                            api.askguru
                                .logEvent({
                                    accessToken: askguruAccessToken,
                                    type: "LIVECHAT_DATA_ERROR",
                                    context: {
                                        error: "Error in getChats recursion call",
                                        error_object: error,
                                    },
                                })
                                .catch((err) => {
                                    console.log("Unable to send an event")
                                })
                        })
                }
            })
            .catch((err) => {
                if (err.response.status === 429 || err.response.status === 408) {
                    delay(500).then(() => {
                        getChats(vendorAccessToken, askguruAccessToken, page_id)
                            .then(([nextChats, nextActiveChats]) => {
                                resolve([chats.concat(nextChats), activeChats.concat(nextActiveChats)])
                            })
                            .catch((error) => {
                                api.askguru
                                    .logEvent({
                                        accessToken: askguruAccessToken,
                                        type: "LIVECHAT_DATA_ERROR",
                                        context: {
                                            error: "Error in getChats after delay retry, code " + err.response.status,
                                            error_object: error,
                                        },
                                    })
                                    .catch((err) => {
                                        console.log("Unable to send an event")
                                    })
                            })
                    })
                } else {
                    reject(err)
                }
            })
    })
}

export const updateSelectedChat = (selectedChat, askguruAccessToken, vendorAccessToken, panicCallback = null) => {
    return new Promise((resolve, reject) => {
        if (!selectedChat) {
            reject()
        }
        retrieveChatHistory(vendorAccessToken, askguruAccessToken, selectedChat.id, selectedChat.user.id).then(
            (chat_history) => {
                console.log("Chat history to update: ", chat_history)

                // Extracting user last messages
                var query = ""
                var i = chat_history.length - 1
                while (i >= 0) {
                    if (chat_history[i].role === "assistant" && query) {
                        break
                    } else if (chat_history[i].role === "user") {
                        query = chat_history[i].content + "\n" + query
                    }
                    i--
                }

                // maybe retrieve current document from backend
                // with ts first and update only if ts is different
                api.askguru
                    .updateCollection({
                        accessToken: askguruAccessToken,
                        collection: "chats",
                        contentType: "chats",
                        chats: [
                            {
                                history: chat_history,
                                user: selectedChat.user,
                            },
                        ],
                        metadata: [
                            {
                                id: selectedChat.id,
                                title: selectedChat.user + "::" + selectedChat.id,
                                timestamp:
                                    chat_history.length === 0 ? 0 : chat_history[chat_history.length - 1].timestamp,
                                security_groups: selectedChat.security_groups,
                            },
                        ],
                    })
                    .then((response) => {
                        console.log("Uploaded new chunks: ", response.data.n_chunks)
                        // resolve(query)
                    })
                    .catch((error) => {
                        if (panicCallback) {
                            api.askguru
                                .logEvent({
                                    accessToken: askguruAccessToken,
                                    type: "LIVECHAT_DATA_ERROR",
                                    context: {
                                        error: "updateCollection failed in updateSelectedChat",
                                        error_object: error,
                                    },
                                })
                                .catch((err) => {
                                    console.log("Unable to send an event")
                                })
                            // panicCallback("Error happened while syncronizing current chat")
                        }
                    })
                resolve(query)
            }
        )
    })
}
