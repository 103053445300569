import CircularIndeterminate from "./ProgressIndeterminate"

const LoadingPlaceholder = ({ state }) => {
    let hint = "Please, wait..."
    let progressVisible = true
    if (state === "loading") {
        hint = "AskGuru is loading, please wait..."
    } else if (state === "fetching") {
        hint = "Fetching your LiveChat history..."
    } else if (state === "failed") {
        hint =
            "Either AskGuru or LiveChat erorr happened, please check permissions. If error persists, please contact support at hello@askguru.ai"
        progressVisible = false
    }
    return (
        <div className="container-fluid">
            <p className="fw-light my-2 text-center">{hint}</p>
            <div
                className="justify-content-center"
                style={{
                    display: progressVisible ? "flex" : "none",
                }}
            >
                <CircularIndeterminate />
            </div>
        </div>
    )
}

export default LoadingPlaceholder
