import React, { useState, useEffect } from "react"
import MainForm from "./components/MainForm"
import Header from "./components/Header"
import config from "./config.json"
import api from "./utils/api"
import LoadingPlaceholder from "./components/LoadingPlaceholder"

import AccountsSDK from "@livechat/accounts-sdk"

const App = () => {
    const [askguruAccessToken, setAskguruAccessToken] = useState(null)
    const [vendorAccessToken, setVendorAccessToken] = useState(null)
    const [tokenState, setTokenState] = useState("loading")

    useEffect(() => {
        const accountsSDK = new AccountsSDK({
            client_id: config.livechatClientId,
            server_url: config.livechatAccountsUrl,
        })
        const authorize = async () => {
            try {
                const authorizeData = await accountsSDK.redirect().authorizeData()
                accountsSDK.verify(authorizeData)
                const { access_token } = authorizeData
                setVendorAccessToken(access_token)
            } catch (error) {
                if (error.identity_exception === "unauthorized") {
                    await accountsSDK.redirect().authorize()
                }
            }
        }
        authorize()
    }, [])

    useEffect(() => {
        if (vendorAccessToken) {
            api.askguru
                .getToken({ vendorAccessToken: vendorAccessToken })
                .then((response) => {
                    setAskguruAccessToken(response.data.access_token)
                    setTokenState("loaded")
                })
                .catch((error) => {
                    console.log(error)
                    setTokenState("failed")
                })
        }
    }, [vendorAccessToken])

    // return mainform only when both tokens are loaded

    if (tokenState !== "loaded") {
        return (
            <div className="d-flex flex-column min-vh-100">
                <Header />
                <div className="container">
                    <LoadingPlaceholder state={tokenState}/>
                </div>
            </div>
        )
    }
    return (
        <div className="d-flex flex-column min-vh-100">
            <Header />
            <MainForm
                vendorAccessToken={vendorAccessToken}
                askguruAccessToken={askguruAccessToken}
                tokenLoadingState={tokenState}
            />
        </div>
    )
}

export default App
