import { useState, useEffect } from "react"
import Alert from "react-bootstrap/Alert"

function AlertDismissible({ message }) {
    const [showAlert, setShowAlert] = useState(true)

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAlert(false)
        }, 8000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    if (!showAlert) {
        return null
    }
    return (
        <Alert variant="danger" dismissible>
            <Alert.Heading>Unknown error happened. Don't worry, dev team notified!</Alert.Heading>
            <p>{message}</p>
        </Alert>
    )
}

export default AlertDismissible
