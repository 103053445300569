import axios from "axios"
import config from "../config"
import qs from "qs"
import { EventSourcePolyfill } from "event-source-polyfill"

const { livechatAPI, livechatApiVersion, livechatAccountsUrl, askguruAPI, askguruApiVersion } = config

const [GET, POST] = ["GET", "POST"]

const createApiRequest = async ({ url, version, method, route, accessToken = "", params = {}, data = {} }) => {
    if (params.stream) {
        const queryString = qs.stringify(params, { arrayFormat: "repeat" })
        const eventSourceUrl = `${url}/${version}${route}?${queryString}`
        const eventSource = new EventSourcePolyfill(eventSourceUrl, {
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })

        return eventSource
    } else {
        return axios({
            method,
            url: url + "/" + version + route,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
                accept: "application/json",
            },
            params,
            data,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            },
            timeout: 60000,
        })
    }
}

const api = {
    livechat: {
        getInfo: ({ accessToken }) => {
            return createApiRequest({
                url: livechatAccountsUrl,
                version: "v2",
                method: GET,
                route: "/info",
                accessToken: accessToken,
            })
        },
        listChats: ({ accessToken, limit = 25, page_id = undefined }) => {
            return createApiRequest({
                url: livechatAPI,
                version: livechatApiVersion,
                method: POST,
                route: "/agent/action/list_chats",
                accessToken: accessToken,
                data: page_id ? { page_id } : { limit },
            })
        },
        listThreads: ({ accessToken, chatId, limit = 100, pageId = undefined }) => {
            return createApiRequest({
                url: livechatAPI,
                version: livechatApiVersion,
                method: POST,
                route: "/agent/action/list_threads",
                accessToken: accessToken,
                data: Object.assign(
                    {},
                    { chat_id: chatId },
                    pageId ? { page_id: pageId } : { sort_order: "asc", limit: limit }
                ),
            })
        },
    },
    askguru: {
        getToken: ({ vendorAccessToken }) => {
            return createApiRequest({
                url: askguruAPI,
                version: askguruApiVersion,
                method: POST,
                route: `/collections/token_livechat`,
                data: vendorAccessToken,
            })
        },
        getCollection: ({ accessToken, collection }) => {
            return createApiRequest({
                url: askguruAPI,
                version: askguruApiVersion,
                method: GET,
                route: `/collections/${collection}`,
                accessToken: accessToken,
            })
        },
        updateCollection: ({
            accessToken,
            collection,
            contentType = "chats",
            metadata,
            chats = undefined,
            documents = undefined,
        }) => {
            return createApiRequest({
                url: askguruAPI,
                version: askguruApiVersion,
                method: POST,
                route: `/collections/${collection}/${contentType}`,
                accessToken: accessToken,
                data: {
                    chats: chats,
                    documents: documents,
                    metadata: metadata,
                },
            })
        },
        getRanking: ({ accessToken, collections, query, document, documentCollection, topK }) => {
            return createApiRequest({
                url: askguruAPI,
                version: askguruApiVersion,
                method: GET,
                route: `/collections/ranking`,
                accessToken: accessToken,
                params: {
                    query,
                    collections,
                    document,
                    document_collection: documentCollection,
                    top_k: topK,
                },
            })
        },
        getAnswer: ({
            accessToken,
            collections,
            query = "",
            document = "",
            documentCollection = "",
            stream = false,
        }) => {
            return createApiRequest({
                url: askguruAPI,
                version: askguruApiVersion,
                method: GET,
                route: `/collections/answer`,
                accessToken: accessToken,
                params: {
                    collections: collections,
                    query: query,
                    document: document,
                    document_collection: documentCollection,
                    stream: stream,
                },
            })
        },
        setReaction: ({ accessToken, requestId, answerCopied = null, likeStatus = null, comment = "" }) => {
            return createApiRequest({
                url: askguruAPI,
                version: askguruApiVersion,
                method: POST,
                route: `/reactions`,
                accessToken: accessToken,
                data: {
                    request_id: requestId,
                    like_status: likeStatus,
                    answer_copied: answerCopied,
                    comment: comment,
                },
            })
        },
        logEvent: ({ accessToken, type, context = {} }) => {
            return createApiRequest({
                url: askguruAPI,
                method: POST,
                version: askguruApiVersion,
                route: `/events`,
                accessToken: accessToken,
                data: {
                    type: type,
                    context: context,
                },
            })
        },
    },
}

export default api
