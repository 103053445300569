export const createLink = ({ id, collection }) => {
    switch (collection) {
        case "chats":
            return `https://my.livechatinc.com/archives/${id}`
        default:
            return id
    }
}

export const createTitle = ({ title, collection }) => {
    switch (collection) {
        case "chats":
            return `Chat with ${title.split("::")[0]}`
        default:
            return title
    }
}
